import React from "react"
import { GlobalStyles } from "twin.macro"

// const Wrapper = tw.div`
//   flex items-center justify-center flex-col h-screen
// `

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      {children}
    </>
  )
}
